import ApiService from '@/shared/services/api.service';
export class DashboardService {

    static getData(params) {
        return new Promise((resolve, reject) => {
            ApiService.query('/dashboard-views', params)
                .then((response) => resolve(response.data))
                .catch((error) => reject(error));
        });
    }

    static getReportsData(params) {
        return new Promise((resolve, reject) => {
            ApiService.query('/reported-applications', params)
                .then((response) => resolve(response.data))
                .catch((error) => reject(error));
        });
    }
}
