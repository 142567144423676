import { DashboardService } from './dashboard-service';
import Errors from '@/shared/error/errors';
import moment from 'moment';

export const GET_DASHBOARDS = 'getDashboards';
export const GET_DASHBOARD_REPORTS = 'getDashboardReports';

export const SET_FILTERS = "setFilters";
export const SET_DASHBOARDS = 'setDashboards';


const state = {
    data: null,
    storeFilters: {
        start_date: {
            start: moment().startOf('year').format('YYYY-MM-DD'),
            end: moment().format('YYYY-MM-DD'),
        }
    },
};

const getters = {
    data: (state) => state.data,
    totalApplications: (state) => state.data?.total_applications || null,
    applicationsByMonth: (state) => state.data?.applications_by_month || null,
    applicationsPerUat: (state) => state.data?.applications_per_uat || null,
    totalApplicationsByType: (state) => state.data?.total_applications_by_type || null,
    applicationsResolutionTermObjective: (state) => state.data?.applications_avg_resolution_goal || null,
    applicationsPerformance: (state) => state.data?.applications_performance || null,
    applicationsAvgResolutionTerm: (_, getters) => getters.applicationsPerformance?.resolution_avg ?? 0,
    filters: (state) => state.storeFilters,
};

const actions = {
    [GET_DASHBOARDS](context, types) {
        context.commit('shared/activateLoading', 'dashboards/getDashboards', { root: true });
        const params = { params: { dashboard_types: types }};
        return new Promise((resolve) => {
            DashboardService.getData(params)
                .then((data) => {
                    context.commit(SET_DASHBOARDS, data);
                    resolve();
                })
                .catch(error => {
                    Errors.handle(error);
                })
                .finally(() => {
                    context.commit('shared/deactivateLoading', 'dashboards/getDashboards', { root: true });
                });
        });
    },
    [GET_DASHBOARD_REPORTS](context, payload) {
        context.commit('shared/activateLoading', 'dashboards/getDashboardReports', { root: true });
        const params = { params: payload};
        return new Promise((resolve) => {
            DashboardService.getReportsData(params)
                .then((data) => {
                    context.commit(SET_DASHBOARDS, data);
                    resolve();
                })
                .catch(error => {
                    Errors.handle(error);
                })
                .finally(() => {
                    context.commit('shared/deactivateLoading', 'dashboards/getDashboardReports', { root: true });
                });
        });
    },
};

const mutations = {
    [SET_DASHBOARDS](state, data) {
        state.data = data;
    },
    [SET_FILTERS](state, { key, value }) {
        state.storeFilters[key] = value;
    }
};


export default {
    namespaced: true,
    state,
    actions,
    mutations,
    getters,
};
